<template>
	<div>
		<pui-intro-layout />
	</div>
</template>

<script>
export default {
	mounted() {
		this.$puiRequests.getRequest(
			'/lspilogin/getDistributionAppData',
			null,
			response => {
				//this.$store.commit('distributionAppData', response.data);

				window.localStorage.setItem('distributionAppData', JSON.stringify(response.data));
				var logoElement = document.getElementsByClassName('login__logo')[0];
				if (logoElement && response.data.logo) {
					logoElement.setAttribute(
						'style',
						"background:url('data:image/png;base64, " +
							response.data.logo +
							"') no-repeat center !important; background-size: cover; opacity: 1;"
					);
				}
				const imgElement = document.getElementsByClassName('pui-login__display-area')[0];
				if (imgElement && response.data.image) {
					imgElement.setAttribute(
						'style',
						"background:url('data:image/png;base64, " + response.data.image + "') no-repeat !important;opacity: 1;"
					);
				}
			},
			e => {
				console.log(e);
			}
		);

		const loginTitle = document.getElementsByClassName('pui-login__title')[0];

		if (loginTitle) {
			var span = document.createElement('div');
			span.innerHTML = '*';
			span.className = 'prevlogin_title';
			loginTitle.parentNode.insertBefore(span, loginTitle);
		}
	}
};
</script>
